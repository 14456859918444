import React, { useState, useEffect } from "react";
import styles from "./AgentProfile.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { useAgentDetails } from "./AgentProfileContext.js";
import PersonalDetails from "./PersonalDetails/PersonalDetails.js";
import ProfessionalInformation from "./ProfessionalInformation/ProfessionalInformation.js";
import PropertyListings from "./PropertyListings/PropertyListings.js";
import ClientInformation from "./ClientInformation/ClientInformation.js";
import SocialDigitalPresence from "./SocialDigitalPresence/SocialDigitalPresence.js";
import AdditionalAttributes from "./AdditionalAttributes/AdditionalAttributes.js";
import Header from "../header/Header.jsx";
import { useAuthContext } from "../context/hook/useAuthContext.js";

const AgentProfile = ({ configuration }) => {
  const { user } = useAuthContext();
  const agentId = user.existingUser._id;

  const {
    personalDetails,
    setPersonalDetails,
    professionalInformation,
    setProfessionalInformation,
    propertyListings,
    setPropertyListings,
    clientInformation,
    setClientInformation,
    socialDigitalPresence,
    setSocialDigitalPresence,
    additionalAttributes,
    setAdditionalAttributes,
  } = useAgentDetails();

  const [loading, setLoading] = useState(true);

  const handleSaveClick = async (e) => {
    e.preventDefault();

    if (
      !personalDetails.firstName ||
      !personalDetails.lastName ||
      !personalDetails.email ||
      !personalDetails.phoneNumber ||
      !personalDetails.city ||
      !personalDetails.state
    ) {
      toast.warning("Please fill in all required fields");
      return;
    }

    try {
      const response = await axios.post(
        `https://api-in4hljwedq-uc.a.run.app/api/agent/save-profile`,
        {
          agentId: agentId,
          personalDetails,
          professionalInformation,
          propertyListings,
          clientInformation,
          socialDigitalPresence,
          additionalAttributes,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
    }
  };

  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        const response = await axios.post(
          `https://api-in4hljwedq-uc.a.run.app/api/agent/fetch-profile`,
          { agentId: agentId }
        );

        const {
          personalDetails,
          professionalInformation,
          propertyListings,
          clientInformation,
          socialDigitalPresence,
          additionalAttributes,
        } = response.data.data;

        setPersonalDetails(personalDetails || {});
        setProfessionalInformation(professionalInformation || {});
        setPropertyListings(propertyListings || {});
        setClientInformation(clientInformation || {});
        setSocialDigitalPresence(socialDigitalPresence || {});
        setAdditionalAttributes(additionalAttributes || {});

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchAgentDetails();
  }, [
    agentId,
    setPersonalDetails,
    setProfessionalInformation,
    setPropertyListings,
    setClientInformation,
    setSocialDigitalPresence,
    setAdditionalAttributes,
  ]);

  return (
    <>
      <Header configuration={configuration} />
      <div className={styles.container}>
        {loading ? (
          <PulseLoader className={styles.loader} color={"#36D7B7"} size={13} />
        ) : (
          <div className={styles.profileSection}>
            <PersonalDetails />
            <ProfessionalInformation />
            <PropertyListings />
            <ClientInformation />
            <SocialDigitalPresence />
            <AdditionalAttributes />

            <div className={styles.submitButton}>
              <button onClick={handleSaveClick} type="submit">
                Save
              </button>
            </div>
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
};

export default AgentProfile;
